import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
// import { graphql } from "gatsby";
//import Img from "gatsby-image"
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import * as style from "./index.module.css";
import { Col, Container, Row } from "react-bootstrap";

// P5
import Sketch from "../components/sketch";
import sketch from "../components/scripts/ramifications";

const PageHeader = styled.h1`
  text-align: center;
`;

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="art in common"
        description="the plattform for arts"
        // image="/logo.png"
        pathname="/"
        // Boolean indicating whether this is an article:
        // article
      />

      <Sketch sketch={sketch} />

      <PageHeader className={style.heading}>
        <div>Think art in common </div>
      </PageHeader>

      

      <Container  fluid="sm" className="mt-4">
        <Row>
          <Col lg={4}>
          <section className={style.translated}>
          <div>
            Is art currently relevant? The platform artincommon.de is a network
            mesh of relationships and encounters, which has an impact on the
            physical space via the virtual space. Offer and need shall connect
            people and lead to cooperations and projects, which works through
            community building. The community helps each other with what it can
            and what it needs. Not only artists are invited, but also people
            from other disciplines. In addition, the theme of mootherhood and
            care work will be explored in the context of art. 
          </div>
        </section>
      
          </Col>

          <Col lg={4}>
        <section className={style.entertext}>
          <div>
            {" "}
            Ist die Kunst gegenwärtig Relevant? Die Plattform artincommon.de ist
            ein Netzwerkgeflecht aus Beziehungen und Begegnungen, dass über den
            virtuellen Raum, in den physischen Raum einwirkt. Angebot und
            Bedürfnis sollen Menschen miteinander verbinden und zu Kooperationen
            und Projekten führen, die über eine Community-Bildung funktioniert.
            Die Community hilft sich gegenseitig mit dem, was Sie kann und was
            Sie braucht. Es sind nicht nur Künstler*innen eingeladen, sondern
            vielmehr sparten übergreifende Leute willkommen. 
          </div>
        </section>
        </Col>
        </Row>
   
      </Container>

     
    </Layout>
  );
};

export default IndexPage;

// export const query = graphql`
//   {
//     headerImage: file(
//       relativePath: { eq: "robots-androids-and-cyborgs-oh-my-1184x360.jpg" }
//     ) {
//       childImageSharp {
//         fluid(maxWidth: 1200) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;
